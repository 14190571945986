// const theme: IDefaultTheme = {
//   colors: {
//     white: '#ffffff',
//     black: '#001E38'
//   }
// };

// https://cdn.dribbble.com/users/634131/screenshots/2012608/material-design-colors.png
import { createMuiTheme } from '@material-ui/core';

export default (createMuiTheme as any)({
  palette: {
    type: 'dark',
    primary: { main: '#3b3e4c' },
    secondary: { main: '#FE6F61' },
    custom: {
      success: '#8BC34A',
      warning: '#FF5722',
      error: '#E51C23',
      secondaryBackground: '#505050',
      action: '#006FC0'
    }
  }
});
